@import url('./colors.css');

html {
  color-scheme: light !important;
}

body {
  background-image: url('../images/background.jpg');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
}
