:root {
  --sys-zindex-bottomness: -900;
  --sys-zindex-below: -1;
  --sys-zindex-default: 1;
  --sys-zindex-above: 2;
  --sys-zindex-dropdown: 100;
  --sys-zindex-sticky: 200;
  --sys-zindex-fixed: 300;
  --sys-zindex-backdrop: 400;
  --sys-zindex-modal: 500;
  --sys-zindex-popover: 600;
  --sys-zindex-tooltip: 700;
  --sys-zindex-topness: 900;
}
