:root {
  --ref-motion-duration-frame: 16.66666666666666667ms;

  --sys-motion-duration-none: calc(2 * var(--ref-motion-duration-frame));
  --sys-motion-duration-small: calc(8 * var(--ref-motion-duration-frame));
  --sys-motion-duration-small-out: calc(6 * var(--ref-motion-duration-frame));
  --sys-motion-duration-medium: calc(16 * var(--ref-motion-duration-frame));
  --sys-motion-duration-medium-out: calc(12 * var(--ref-motion-duration-frame));
  --sys-motion-duration-large: calc(18 * var(--ref-motion-duration-frame));
  --sys-motion-duration-large-out: var(--sys-motion-duration-medium);

  --sys-motion-easing-linear: cubic-bezier(0.4, 0, 0.6, 1);
  --sys-motion-easing-incoming: cubic-bezier(0, 0, 0.2, 1);
  --sys-motion-easing-exiting: cubic-bezier(0.4, 0, 1, 1);
  --sys-motion-easing-in-out: cubic-bezier(0.4, 0, 0.2, 1);
}
