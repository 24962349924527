@supports (padding-top: 20px) {
  :root {
    --ion-safe-area-top: var(--ion-statusbar-padding);
  }
}

@supports (padding-top: constant(safe-area-inset-top)) {
  :root {
    --ion-safe-area-top: constant(safe-area-inset-top);
    --ion-safe-area-bottom: constant(safe-area-inset-bottom);
    --ion-safe-area-left: constant(safe-area-inset-left);
    --ion-safe-area-right: constant(safe-area-inset-right);
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  :root {
    --ion-safe-area-top: env(safe-area-inset-top);
    --ion-safe-area-bottom: env(safe-area-inset-bottom);
    --ion-safe-area-left: env(safe-area-inset-left);
    --ion-safe-area-right: env(safe-area-inset-right);
  }
}
