html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

html {
  font-size: 62.5%; /* 10px */
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  contain: layout size style;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-user-drag: none;
  -ms-content-zooming: none;
  -ms-touch-action: pan-x pan-y pinch-zoom;
  touch-action: pan-x pan-y pinch-zoom;
  transform: translateZ(0);
  overflow: hidden;
  overflow: clip;
  overscroll-behavior-y: none;

  /* Theme */
  color: var(--sys-color-on-background);
  background-color: var(--sys-color-background);
  font-family: var(--sys-typescale-body-medium-font-family-name);
  font-weight: var(--sys-typescale-body-medium-font-weight);
  font-size: var(--sys-typescale-body-medium-font-size);
  letter-spacing: var(--sys-typescale-body-medium-letter-spacing);
  line-height: var(--sys-typescale-body-medium-line-height);
}

.noscript {
  text-align: center;
  font-size: 2rem;
  padding: 4rem 2rem;
}

.noscript p {
  margin: 0.5em 0;
}

body.no-scroll {
  overflow: hidden;
}

alwatr-pwa[unresolved] {
  display: block;
  text-align: center;
  /* TODO: skeleton */
}
