/* Vazirmatn Round-Dots Variable (Without-Latin) */
@font-face {
  font-family: 'vazirmatn-roundot-vf';
  font-weight: 100 900;
  src: url('vazirmatn-roundot/vazirmatn-roundot[wght].woff2') format('woff2 supports variations'),
       url('vazirmatn-roundot/vazirmatn-roundot[wght].woff2') format('woff2-variations');
  font-display: block;
  unicode-range: U+0600-06FF, U+0750-077F, U+08A0-08FF, U+FB50-FDFF, U+FE70-FEFF, U+1EE00-1EEFF;
}

/* Vazirmatn Round-Dots (Without-Latin, None VF Fallback) */
@font-face {
  font-family: 'vazirmatn-roundot';
  font-weight: 100;
  src: url('vazirmatn-roundot/vazirmatn-roundot-100.woff') format('woff');
  font-display: block;
  unicode-range: U+0600-06FF, U+0750-077F, U+08A0-08FF, U+FB50-FDFF, U+FE70-FEFF, U+1EE00-1EEFF;
}
@font-face {
  font-family: 'vazirmatn-roundot';
  font-weight: 200;
  src: url('vazirmatn-roundot/vazirmatn-roundot-200.woff') format('woff');
  font-display: block;
  unicode-range: U+0600-06FF, U+0750-077F, U+08A0-08FF, U+FB50-FDFF, U+FE70-FEFF, U+1EE00-1EEFF;
}
@font-face {
  font-family: 'vazirmatn-roundot';
  font-weight: 300;
  src: url('vazirmatn-roundot/vazirmatn-roundot-300.woff') format('woff');
  font-display: block;
  unicode-range: U+0600-06FF, U+0750-077F, U+08A0-08FF, U+FB50-FDFF, U+FE70-FEFF, U+1EE00-1EEFF;
}
@font-face {
  font-family: 'vazirmatn-roundot';
  font-weight: 400;
  src: url('vazirmatn-roundot/vazirmatn-roundot-400.woff') format('woff');
  font-display: block;
  unicode-range: U+0600-06FF, U+0750-077F, U+08A0-08FF, U+FB50-FDFF, U+FE70-FEFF, U+1EE00-1EEFF;
}
@font-face {
  font-family: 'vazirmatn-roundot';
  font-weight: 500;
  src: url('vazirmatn-roundot/vazirmatn-roundot-500.woff') format('woff');
  font-display: block;
  unicode-range: U+0600-06FF, U+0750-077F, U+08A0-08FF, U+FB50-FDFF, U+FE70-FEFF, U+1EE00-1EEFF;
}
@font-face {
  font-family: 'vazirmatn-roundot';
  font-weight: 600;
  src: url('vazirmatn-roundot/vazirmatn-roundot-600.woff') format('woff');
  font-display: block;
  unicode-range: U+0600-06FF, U+0750-077F, U+08A0-08FF, U+FB50-FDFF, U+FE70-FEFF, U+1EE00-1EEFF;
}
@font-face {
  font-family: 'vazirmatn-roundot';
  font-weight: 700;
  src: url('vazirmatn-roundot/vazirmatn-roundot-700.woff') format('woff');
  font-display: block;
  unicode-range: U+0600-06FF, U+0750-077F, U+08A0-08FF, U+FB50-FDFF, U+FE70-FEFF, U+1EE00-1EEFF;
}
@font-face {
  font-family: 'vazirmatn-roundot';
  font-weight: 800;
  src: url('vazirmatn-roundot/vazirmatn-roundot-800.woff') format('woff');
  font-display: block;
  unicode-range: U+0600-06FF, U+0750-077F, U+08A0-08FF, U+FB50-FDFF, U+FE70-FEFF, U+1EE00-1EEFF;
}
@font-face {
  font-family: 'vazirmatn-roundot';
  font-weight: 900;
  src: url('vazirmatn-roundot/vazirmatn-roundot-900.woff') format('woff');
  font-display: block;
  unicode-range: U+0600-06FF, U+0750-077F, U+08A0-08FF, U+FB50-FDFF, U+FE70-FEFF, U+1EE00-1EEFF;
}

/* Global page typography */
:root {
  --ref-font-family-vazirmatn-roundot: 'vazirmatn-roundot-vf', 'vazirmatn-roundot';
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  font-feature-settings: 'calt' 1, 'tnum' 0;
  font-family:  'vazirmatn-roundot-vf',
                'vazirmatn-roundot',
                system-ui,
                -apple-system,
                BlinkMacSystemFont,
                'Segoe UI',
                'Roboto',
                'Oxygen',
                'Ubuntu',
                'Cantarell',
                'Open Sans',
                'Helvetica Neue',
                'Arial',
                sans-serif,
                'Apple Color Emoji',
                'Segoe UI Emoji',
                'Segoe UI Symbol';
}
